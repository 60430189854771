import React from 'react'
//Types
import { SectionTeam } from '../../../types/sections'
//Components
import { Box, Flex, Heading, Image, SocialButton, Text } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

export interface TeamProps {
  data: SectionTeam
}

const Team: React.FC<TeamProps> = ({ data }) => {
  const { id, sectionId, headline, subHeadline, teamMembers } = data
  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Box mb={[8, 9, 10]}>
          <Row center={'xs'}>
            <Col xs={12} md={8} lg={6}>
              <Heading as={'h3'} textAlign={'center'}>
                {headline}
              </Heading>
              {!!subHeadline && (
                <Box mt={5}>
                  <Heading as={'h6'} textAlign={'center'}>
                    {subHeadline}
                  </Heading>
                </Box>
              )}
            </Col>
          </Row>
        </Box>
        <Row>
          {teamMembers.map((tm) => {
            const { id, name, profilePicture, role, socials } = tm
            return (
              <Col key={id} xs={12} sm={6} md={4} lg={3}>
                <Box height={'100%'} pb={36}>
                  <Flex
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}
                    height={'100%'}
                    bg={'purpleBlueLight'}
                    borderRadius={'regular'}
                    p={6}
                  >
                    <Box flex={'0 0 auto'}>
                      <Box width={140} height={140} mx={'auto'} borderRadius={'circle'} overflow={'hidden'}>
                        {profilePicture && (
                          <Image
                            draggable={false}
                            alt={profilePicture.alt || name}
                            image={profilePicture.gatsbyImageData}
                            style={{ overflow: 'hidden', borderRadius: '9999px' }}
                            imgStyle={{ borderRadius: '9999px' }}
                          />
                        )}
                      </Box>
                      <Box mt={5} textAlign={'center'}>
                        <Text fontWeight={'semi'} fontSize={4} lineHeight={1.2}>
                          {name}
                        </Text>
                        {!!role && (
                          <Box mt={2}>
                            <Text fontSize={2} lineHeight={1.2}>
                              {role}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {socials.length > 0 && (
                      <Flex flex={'0 0 auto'} mt={5} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                        {socials.map((s) => (
                          <Box key={s.id} mx={2} flex={'0 0 auto'}>
                            <SocialButton {...s} />
                          </Box>
                        ))}
                      </Flex>
                    )}
                  </Flex>
                </Box>
              </Col>
            )
          })}
        </Row>
      </Grid>
    </Box>
  )
}

export default Team
